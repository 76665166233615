//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-332:_2940,_5020,_8744,_1916,_5116,_3540,_5808,_7548;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-332')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-332', "_2940,_5020,_8744,_1916,_5116,_3540,_5808,_7548");
        }
      }catch (ex) {
        console.error(ex);
      }